//routes
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
//containers
import Home from './conteiners/Home'
import Error404 from './conteiners/errors/Error404'
import Divids from './conteiners/Dividends';
import Financieros from './conteiners/Financieros';

function App() {
  return (
    <Router>
        <Routes>
          {/* Error Display */}
          <Route path="*" element={<Error404/>}/>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/divs' element={<Divids/>}/>
          <Route exact path='/fin' element={<Financieros/>}/>

        </Routes>
      </Router>
  );
}

export default App;
